<template>
  <basic-modal @open="openModal" width="1090" transition="slide-x-transition">
    <template #header>
      <divider
        title="user details"
        sub-title="THE DETAILS YOU NEED"
        size="large"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-14 pt-1">
        <section-title title="basic info"></section-title>
        <ul class="request__detail pl-0">
          <request-details-information
            title="ID"
            :subTitle="clientDetails.id"
          ></request-details-information>
          <request-details-information
            title="NAME"
            :subTitle="clientDetails.name"
          ></request-details-information>
          <request-details-information
            title="FIRST NAME"
            :subTitle="clientDetails.first_name"
          ></request-details-information>
          <request-details-information
            title="FAMILY NAME"
            :subTitle="clientDetails.last_name"
          ></request-details-information>
          <request-details-information
            title="japanese NAME"
            :subTitle="clientDetails.name_jpn"
          ></request-details-information>
          <request-details-information
            title="japanese LAST NAME"
            :subTitle="clientDetails.last_name_jpn"
          ></request-details-information>
          <request-details-information
            title="japanese FIRST NAME"
            :subTitle="clientDetails.first_name_jpn"
          ></request-details-information>
          <request-details-information
            title="COUNTRY NAME"
            :subTitle="clientDetails.country ? clientDetails.country.name : ''"
          ></request-details-information>
          <request-details-information
            title="japanese COUNTRY NAME"
            :subTitle="clientDetails.country ? clientDetails.country.name_jpn : ''"
          ></request-details-information>
          <request-details-information
            title="CREATED AT"
            :subTitle="clientDetails.created_at"
          ></request-details-information>
          <request-details-information
            title="CORPORATE"
            :subTitle="clientDetails.corporate"
          ></request-details-information>
          <request-details-information
            title="GENDER"
            :subTitle="clientDetails.gender ? clientDetails.gender.name : ''"
          ></request-details-information>
          <request-details-information
            title="CELL PHONE"
            :subTitle="clientDetails.cell_phone"
          ></request-details-information>
          <request-details-information
            title="EMAIL ADDRESS"
            :subTitle="clientDetails.email"
          ></request-details-information>
          <request-details-information
            title="NATIONALITY"
            :subTitle="clientDetails.ethnic ? clientDetails.ethnic.name : ''"
          ></request-details-information>
          <request-details-information
            title="japanese NATIONALITY"
            :subTitle="clientDetails.ethnic ? clientDetails.ethnic.name_jpn : ''"
          ></request-details-information>
          <request-details-information
            title="BIRTHDAY"
            :subTitle="clientDetails.birthday"
          ></request-details-information>
          <request-details-information
            title="CITY"
            :subTitle="clientDetails.city"
          ></request-details-information>
          <request-details-information
            title="BIO"
            :subTitle="clientDetails.bio"
          ></request-details-information>
          <request-details-information
            title="japanese BIO"
            :subTitle="clientDetails.bio_jpn"
          ></request-details-information>
          <li class="client__details__information">
            <div class="d-block ml-1">
              <div class="client__details__information--title">model types</div>
              <div class="client__details__information--sub-title d-flex">
                <v-chip
                  class="mr-1 client__details__information--sub-title--block"
                  color="#e6e6e68f"
                  text-color="black"
                  label
                  x-small
                  v-for="modelType in clientDetails.model_types"
                  :key="modelType.id"
                >
                  {{ modelType.name }}
                </v-chip>
              </div>
            </div>
          </li>
          <li class="client__details__information">
            <div class="d-block ml-1">
              <div class="client__details__information--title">languages</div>
              <div class="client__details__information--sub-title d-flex">
                <div
                  v-for="language in clientDetails.languagesWithLevel"
                  :key="language.id"
                >
                  <v-chip
                    class="mr-1 client__details__information--sub-title--block"
                    color="#e6e6e68f"
                    text-color="black"
                    label
                    x-small
                    v-if="language.name && language.level_name"
                  >
                    {{ language.name }} - {{ language.level_name }}
                  </v-chip>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="mt-4 px-14 pt-1">
        <section-title title="activities"></section-title>
        <ul class="d-flex mb-0 pl-0 mt-2">
          <request-details-information
            title="TOTAL REQUESTS"
            :subTitle="clientDetails.requestCount"
          ></request-details-information>
          <request-details-information
            title="TOTAL APPROVED"
            :subTitle="clientDetails.requestApprovedCount"
          ></request-details-information>
          <request-details-information
            title="TOTAL REJECT"
            :subTitle="clientDetails.requestRejectedCount"
          ></request-details-information>
          <request-details-information
            title="WAITING"
            :subTitle="clientDetails.requestWaitingCount"
          ></request-details-information>
        </ul>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-3 pt-4">
        <block-button
          class="btn-allright btn__size__16 site__button bg-transparent width-auto"
          text="IT'S ALLRIGHT, THANK YOU"
          @click.native="$_closeModal()"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "../Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "../Global/Section/RequestDetailsInformation.vue";
export default {
  name: "modal_clientDetails",
  components: { SectionTitle, RequestDetailsInformation },
  data() {
    return {
      clientDetails: {},
    };
  },
  methods: {
    openModal({ model }) {
      this.clientDetails = model;
    },
  },
};
</script>

<style scoped>
.btn-allright {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
  position: relative;
  top: -0.6rem;
  letter-spacing: 3px;
  margin: 0px 20px;
}
/* .request__detail {
  padding-right: 200px;
} */
.request__detail li {
  display: inline-flex;
  margin-right: 65px;
  word-break: break-all;
}
.request__detail li:nth-child(6) {
  margin-right: 140px;
}
.client__details__information {
  display: flex;
  margin: 0 50px 0 0;
}
.client__details__information::before {
  height: 20px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 0;
}
.client__details__information--title {
  color: var(--color-smoky);
  font-size: 11px;
  font-family: "Montserrat";
  text-transform: uppercase;
}
.client__details__information--sub-title--block {
  font-family: "Montserrat";
}
</style>
