<template>
  <v-form @submit.prevent="submit" class="h-100">
    <list :title="$_t('filters')" :filter="true" class="h-100">
      <hr class="mt-0" />
      <v-row>
        <v-col cols="12">
          <v-text-field
              :label="$_t('clientFirstName')"
              color="SonicSilver"
              v-model="filter.first_name.val"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('clientLastName')"
              color="SonicSilver"
              v-model="filter.last_name.val"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('clientLastNameJapanese') + ' japanese'"
              color="SonicSilver"
              v-model="filter.last_name_jpn.val"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
              :label="$_t('clientFirstNameJapanese')+' japanese'"
              color="SonicSilver"
              v-model="filter.first_name_jpn.val"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-text-field
              :label="$_t('clientEmail')"
              color="SonicSilver"
              v-model="filter.email.val"
              outlined
              dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-autocomplete
              :items="roles"
              v-model="userRole"
              :label="$_t('clientRole')"
              outlined
              dense
              color="SonicSilver"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="py-0">
          <date-input
              :label="$_t('dateAfter')"
              color="SonicSilver"
              v-model="filter.created_at.val1"
              outlined
              dense
          ></date-input>
        </v-col>
        <v-col cols="12" class="py-0">
          <date-input
              :label="$_t('dateBefore')"
              color="SonicSilver"
              v-model="filter.created_at.val2"
              outlined
              dense
          ></date-input>
        </v-col>
        <v-col cols="12" class="py-0">
          <block-button
              height="30"
              class=""
              :text="$_t('selectAll')"
              color="black"
              @click.native="selectAll('getCountries','country_id')"
              outlined
              dense
          ></block-button>
          <block-button
              height="30"
              class="btn__size__14"
              :text="$_t('clearAll')"
              color="black"
              @click.native="clearAll('country_id')"
              outlined
              dense
          ></block-button>
          <v-autocomplete
              class="pt-0 mt-0"
              :items="getCountries"
              item-value="id"
              item-text="name"
              :label="$_t('country')"
              color="SonicSilver"
              v-model="filter.country_id.val"
              outlined
              dense
              multiple
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" class="pr-0">
          <block-button
              height="30"
              class="w-100 cancel__button br__mode btn__size__14"
              :text="$_t('clear')"
              color="black"
              @click.native="clearFilter"
          ></block-button>
        </v-col>
        <v-col cols="8" class="pl-1">
          <block-button
              height="30"
              class="w-100 site__button width-auto add__mode btn__size__18"
              :text="$_t('execute')"
              type="submit"
          ></block-button>
        </v-col>
      </v-row>
    </list>
  </v-form>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
const defaultFilter = {
  created_at: {
    type: "between",
    val1: null,
    val2: null,
    name: "created at",
  },
  first_name: {
    type: "like",
    val: null,
    name: "user first name",
  },
  last_name: {
    type: "like",
    val: null,
    name: "user last name",
  },
  last_name_jpn: {
    type: "like",
    val: null,
    name: "user last name japanese",
  },
  first_name_jpn: {
    type: "like",
    val: null,
    name: "user first name japanese",
  },
  country_id: {
    type: "in",
    val: null,
    name: "country",
    values: [],
    multiple: true,
  },
  email: {
    type: "like",
    val: null,
    name: "email",
  },
  type: {
    type: "in",
    value: null,
    val: null,
    name: "user role",
  },
};
export default {
  data() {
    return {
      sorts: "created_at,desc",
      filter: {},
      roles: ["client", "model", "manager"],
      sortBy: [
        { name: "joined date", id: "created_at,desc" },
      ],
    };
  },
  computed: {
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("user", ["getFiltersUser"]),
    userRole: {
      get() {
        return this.filter.type.val;
      },
      set(value) {
        this.filter.type.val = value;
        this.filter.type.value = value;
      },
    },
  },
  methods: {
    ...mapActions("country", ["loadCountries"]),
    selectAll(model,filter_model){
      this.filter[filter_model].val = this[model].map((item)=>{
        return item.id;
      })
    },
    clearAll(filter_model){
      this.filter[filter_model].val = null
    },
    submit() {
      this.setFilterValues();
      this.setIsFiltered(true);
      this.SET_FILTER_USER(cloneDeep(this.filter));
      this.SET_SORT_USER(cloneDeep(this.sorts));
      this.SET_USERS([]);
      this.loadUsers();
    },
    setFilterValues() {
      this.filter.country_id.values = this.getCountries;
    },
    ...mapMutations("user", ["SET_FILTER_USER", "SET_SORT_USER","SET_USERS"]),
    ...mapActions("user", ["loadUsers", "setIsFiltered"]),
    clearFilter() {
      this.setIsFiltered(false);
      this.filter = cloneDeep(defaultFilter);
      this.SET_FILTER_USER(cloneDeep(this.filter));
      this.SET_SORT_USER(this.sorts);
      this.SET_USERS([]);
      this.loadUsers();
    },
    loadPastFilter() {
      this.filter = cloneDeep({ ...defaultFilter, ...this.getFiltersUser });
    },
  },
  created() {
    this.loadCountries();
    this.loadPastFilter();
  },
};
</script>
