<template>
  <div>
    <div
      :class="`mt-2 theme-${item[itemColor]}`"
      v-for="item in items"
      :key="item.id"
      @click="selectItem(item)"
    >
      <div
        class="modal-alert-small-button"
        :class="{ active: itemSelected == item[itemValue] }"
      >
        <div class="modal-alert-small-button-title">{{ item[itemText] }}</div>
        <div class="modal-alert-small-button--sub-title">
          {{ item[itemHint] }}
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
export default {
  data() {
    return {
      isActive: 1,
    };
  },
  props: {
    value: {
      default: null,
    },
    items: {
      default: () => [],
    },
    itemValue: {
      default: "id",
    },
    itemText: {
      default: "name",
    },
    itemHint: {
      default: "description",
    },
    itemColor: {
      default: "color",
    },
  },
  methods: {
    selectItem(value) {
      this.itemSelected = value[this.itemValue];
    },
  },
  computed: {
    itemSelected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-alert-small-button {
  border: 1px solid var(--color-light-gray);
  border-radius: 4px;
  padding: 12px 19px;
  cursor: pointer;
}
.modal-alert-small-button-title {
  font-family: "Montserrat";
  font-size: 23px;
  color: var(--color-black);
  letter-spacing: 8px;
  text-transform: uppercase;
}
.modal-alert-small-button--sub-title {
  font-family: "Montserrat";
  font-size: 12px;
  color: var(--color-smoky);
}

@import "@Styles/setup/variables";
@each $color, $value in $colors {
  .theme-#{""
    + $color}
    .modal-alert-small-button.active
    .modal-alert-small-button-title,
  .theme-#{""
    + $color}
    .modal-alert-small-button.active
    .modal-alert-small-button--sub-title {
    color: $value;
  }
    .theme-#{"" + $color} .modal-alert-small-button.active {
    border: 1px solid $value;
  }
}
@each $color, $value in $bgColors {
  .theme-#{"" + $color} .modal-alert-small-button.active {
    background-color: $value;
  }
}
</style>