<template>
  <basic-modal @open="setData" width="800" transition="slide-x-transition">
    <template #header>
      <divider title="Send Email" sub-title="send email to model" size="small"></divider>
    </template>
    <template #default>
      <div class="mt-4 px-6 pr-13">
        <v-row>
          <v-col cols="12" md="8">
            <v-autocomplete
              label="email template"
              color="SonicSilver"
              class="mt-0 pt-0"
              :items="templates"
              item-value="id"
              item-text="title"
              outlined
              dense
              return-object
              v-model="form.email_template"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field
              label="subject"
              color="SonicSilver"
              class="mt-0 pt-0"
              v-model="subject"
              :disabled="form.email_template.id !== -1"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <div v-if="showEditor">
          <editor v-model="message" :disabled="form.email_template.id !== -1" :init="init"></editor>
        </div>
<!--        <v-textarea-->
<!--          label="message"-->
<!--          color="SonicSilver"-->
<!--          class="mt-0 pt-0"-->
<!--          v-model="message"-->
<!--          :disabled="form.email_template.id !== -1"-->
<!--          outlined-->
<!--          dense-->
<!--        ></v-textarea>-->
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-5 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel btn__size__18"
          text="cancel"
          icon="WMi-cancel-linear"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign site__button width-200 ml-5 btn__size__16"
          text="send email"
          icon="WMi-message-reply-text"
          :loading="requestLoading"
          @click.native="send"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import { mapActions } from "vuex";
import { makeid } from "@/utils/math";
import { initTiny } from "@/plugins/tinymce/tinymce";
import Editor from "@tinymce/tinymce-vue";
const RANDOM_TOKEN = makeid(50);
var formDefault = {
  thumbnail: "https://via.placeholder.com/1280x720",
  file_batch_id: RANDOM_TOKEN,
  tinymce_batch_id: makeid(50),
  description: "",
};
export default {
  name: "modal_sendEmail",
  data() {
    return {
      showEditor: false,
      init: initTiny(formDefault.tinymce_batch_id,this.$_getLocale(),'email','attachments'),
      form: {
        email_template: { id: -1, title: "no template" },
      },
      requestLoading: false,
    };
  },
  components: {
    Editor
  },
  props:['templates'],
  computed: {
    subject: {
      get() {
        if (this.form.email_template.id === -1) {
          return this.form.subject;
        } else {
          return this.form.email_template.title;
        }
      },
      set(value) {
        if (this.form.email_template.id === -1) {
          this.form.subject = value;
        } else {
          this.form.subject = null;
        }
      },
    },
    message: {
      get() {
        if (this.form.email_template.id === -1) {
          return this.form.message;
        } else {
          return this.form.email_template.message;
        }
      },
      set(value) {
        if (this.form.email_template.id === -1) {
          this.form.message = value;
        } else {
          this.form.message = null;
        }
      },
    },
  },
  methods: {
    setData({ model }) {
      if (model) {
        this.form = { ...this.form, ...model };
      }
    },
    ...mapActions("user", ["sendEmail"]),
    async send() {
      try {
        this.requestLoading = true;
        const response = await this.sendEmail(this.form);
        this.$_closeModal();
        this.$_openModal("emailConfirmation", { model: response });
      } catch (e) {
        return e;
      } finally {
        this.requestLoading = false;
      }
    },
  },
  created() {
    // this.loadTemplates();
  },
  mounted() {
    this.showEditor = true;
  }
};
</script>
<style scoped>
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
</style>
