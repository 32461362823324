<template>
  <list :title="$_t('users')" class="wa__large pb-10">
    <hr class="mt-0" />
    <template #header-btn>
      <block-button
        text="add"
        icon="WMi-plus-linear"
        height="37"
        class="px-10 btn__size__18 site__button add__mode width-200 mt-n1"
        @click.native="$_openModal('add_user')"
      ></block-button>
    </template>
    <template>
      <v-row class="px-5 pb-1 d-none d-md-flex">
        <Checkbox
          @change="toggleSelectAll"
          name="checkbox"
          id="checkbox"
          class="mt-2"
          v-model="getSelectAllCheckbox"
        />
        <v-col cols="2" class="wa__client__title--title"
          ><span>{{ $_t("cellphoneOrName") }}</span></v-col
        >
        <v-col cols="3" class="wa__client__title--title pl-16"
          ><span class="pl-10">{{ $_t("role") }}</span></v-col
        >
        <v-col cols="4" class="wa__client__title--title"
          ><span>{{ $_t("request") }}</span></v-col
        >
        <v-col
          cols="2"
          class="wa__client__title--title text-right d-flex justify-content-end"
          ><span>{{ $_t("controls") }}</span></v-col
        >
      </v-row>
      <data-iterator-load-more
        :items="getUsers"
        :is-filtered="isFilteredUser"
        @pagination="changePagination"
        :pagination="getPaginationUser"
        :loading="getUserLoading"
        class="row mt-0 px-3"
      >
        <template v-slot:loading>
          <loading />
        </template>
        <template #no-items>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/product.gif')" route-name="AddProduct" textFa=" هنوز هیچ محصولی ثبت نکرده اید! " textEn=" No Products Yet! " themeColor="product" btnText="ثبت اولین  محصول" btnIcon="plus" /> -->
          <no-items />
        </template>
        <template #no-results>
          <!-- fix component -->
          <!-- <no-items :src="$_staticPath('images/global/animated/no-result.gif')" textFa=" متاسفانه محصولی مطابق با فیلتر های شما پیدا نکردیم! " textEn=" Sorry, No Product! " themeColor="product" btnText=" حذف فیلتر ها " /> -->
          <no-items />
        </template>

        <template v-slot:items="{ item }">
          <client-item :key="item.id" :client="item" />
        </template>
      </data-iterator-load-more>
      <div></div>
      <div>
        <modal-client-details v-if="isModal('modal_clientDetails')" />
      </div>
      <!-- --------------------------------------------------------------------
                            Modals :: BEGIN
        ---------------------------------------------------------------------->
      <div class="text-center">
        <sendEmailModal :templates="getUserTemplates" v-if="isModal('modal_sendEmail')" />
        <AddUserModal v-if="isModal('modal_add_user')" />
        <emailConfirmationModal
          v-if="isModal('modal_emailConfirmation')"
          :filters="getFiltersUser"
        />
      </div>
      <!-- --------------------------------------------------------------------
                                    Modals :: END
        ---------------------------------------------------------------------->
    </template>
  </list>
</template>

<script>
import ClientItem from "./Item";
import ModalClientDetails from "../Request/ModalClient";
import SendEmailModal from "./SendEmail.vue";
import AddUserModal from "./Modal/Add.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import emailConfirmationModal from "@/components/Global/Modal/EmailConfirmation.vue";
import DataIteratorLoadMore from "../Global/Input/DataIteratorLoadMore";
export default {
  components: {
    DataIteratorLoadMore,
    ClientItem,
    ModalClientDetails,
    SendEmailModal,
    emailConfirmationModal,
    AddUserModal,
  },
  computed: {
    ...mapGetters("user", [
      "getUsers",
      "getPaginationUser",
      "getFiltersUser",
      "isFilteredUser",
      "getUserLoading",
      "getSelectAllCheckbox",
    ]),
    ...mapGetters("modal", ["isModal"]),
    ...mapGetters("template", [
      "getUserTemplates"
    ]),
  },
  methods: {
    ...mapActions("user", ["loadUsers", "toggleSelectAll"]),
    ...mapActions("template", ["loadTemplates"]),
    ...mapMutations("user", ["SET_PAGINATION", "SET_USERS", "DESTROY_HISTORY"]),
    changePagination(page) {
      if (this.getPaginationUser.page !== page) {
        this.SET_PAGINATION({
          pagination: page,
        });
        this.loadUsers();
      }
    },
  },
  created() {
    this.changePagination(1);
    this.loadTemplates();
  },
  destroyed() {
    this.DESTROY_HISTORY()
    this.SET_USERS([])
  }
};
</script>

<style scoped>
.wa__client__title--title {
  font-family: "Montserrat-light";
  font-size: 12px;
  color: var(--color-gray);
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
