<template>
  <v-row v-if="getUserNotSelected" class="section-bordered theme-cyan ma-0 wrap">
    <v-expansion-panels v-model="panel" :readonly="readonly" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <v-row class="ma-0 pt-2">
            <v-col lg6>
              <v-badge color="red" right>
                <template v-slot:badge>
                  <span> {{ getUserNotSelected.length }} </span>
                </template>
                <h3>users not selected</h3>
                <div class="gray--text mt-2">choice action</div>
              </v-badge>
            </v-col>
            <v-col lg6 xs12 class="flex-justified-left" style="padding-left: 52px">
              <block-button
                height="30"
                class="btn__modal--assign ml-5 site__button width-auto"
                text="send email"
                icon="WMi-message-reply-text"
                @click.native="$_openModal('sendEmail', { model: getUserNotSelected })"
              ></block-button>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-chip
            class="ma-1"
            v-for="(user, i) in getUserNotSelected"
            v-bind:key="i"
            @click:close="removeFromNotSelected(user)"
            close
            close-icon="WMi-cancel"
          >
            {{ user.name.trim()=='' ? user.name_jpn : user.name }}
          </v-chip>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: function () {
    return {
      panel: [1, 1],
      readonly: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserNotSelected"]),
  },
  methods: {
    ...mapActions("user", ["removeFromNotSelected"]),
  },
};
</script>

<style scoped lang="scss">
.Filters {
  margin: 20px 0px 10px 0px;
}
</style>
