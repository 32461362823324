<template>
  <basic-modal @open="setData" width="1450" transition="slide-x-transition">
    <template #header>
      <divider
        :title="(form.id ? 'edit' : 'add') + '  USER'"
        sub-title="lets do business"
        size="small"
      ></divider>
    </template>
    <template #default>
      <v-form ref="userForm">
        <div class="mt-4 px-12">
          <div class="w-75">
            <v-row v-if="$_getlocaleParam() === 'en'">
              <v-col cols="12" sm="3">
                <v-text-field
                  label="first Name"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.first_name"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-text-field
                  label="last name"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.last_name"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-text-field
                  label="Japanese last name"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.last_name_jpn"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-text-field
                  label="Japanese first Name"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.first_name_jpn"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="3" class="pl-1">
                <v-text-field
                    label="Japanese last name"
                    color="SonicSilver"
                    outlined
                    dense
                    v-model="form.last_name_jpn"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-text-field
                    label="Japanese first Name"
                    color="SonicSilver"
                    outlined
                    dense
                    v-model="form.first_name_jpn"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  label="first Name"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.first_name"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-text-field
                  label="last name"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.last_name"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="w-75">
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  label="email"
                  color="SonicSilver"
                  outlined
                  dense
                  :disabled="!!form.id"
                  v-model="form.email"
                  :rules="requiredRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <date-input
                  label="birthday"
                  color="SonicSilver"
                  outlined
                  dense
                  v-model="form.birthday"
                  :max="today"
                ></date-input>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-autocomplete
                  item-value="id"
                  item-text="name"
                  label="nationality"
                  color="SonicSilver"
                  outlined
                  dense
                  :items="getEthnics"
                  v-model="form.ethnic_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3" class="pl-1">
                <v-autocomplete
                  item-value="id"
                  item-text="name"
                  label="gender"
                  color="SonicSilver"
                  outlined
                  dense
                  :items="getGenders"
                  v-model="form.gender_id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </div>
          <div class="w-75">
            <v-row>
              <v-col cols="12" sm="9">
                <v-autocomplete
                  class="pt-0 multiple"
                  color="SonicSilver"
                  item-value="id"
                  item-text="name"
                  required
                  attach
                  chips
                  label="model types you interested in"
                  multiple
                  dense
                  outlined
                  return-object
                  item-color="black"
                  :items="getModelTypes"
                  v-model="form.model_types"
                >
                  <template v-slot:selection="data">
                    <v-chip class="mt-1" label small :input-value="data.selected">
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>
          <div>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="bio"
                  color="SonicSilver"
                  class="mt-0 pt-0"
                  outlined
                  dense
                  v-model="form.bio"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pt-1">
                <v-textarea
                  label="japanese bio"
                  color="SonicSilver"
                  class="mt-0 pt-0"
                  outlined
                  dense
                  v-model="form.bio_jpn"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-form>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div class="px-7 pt-2 pb-3 d-flex justify-space-between align-items-center w-100">
        <block-button
          class="btn__modal--cancel pl-0 btn__size__18"
          text="cancel"
          icon="WMi-cancel-1"
          text-mode="text"
          @click.native="$_closeModal()"
        ></block-button>
        <block-button
          height="30"
          class="btn__modal--assign ml-5 site__button width-auto btn__size__16"
          :text="(form.id ? 'update' : 'add') + ' the user'"
          :loading="sendRequestLoading"
          @click.native="addUser"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import {requiredRules} from "@/mixins/validate"
import { mapGetters, mapActions } from "vuex";
export default {
  name: "modal_add_user",
  data() {
    return {
      form: {},
      sendRequestLoading: false,
      today: new Date().toISOString().slice(0, 10),
    };
  },
  computed: {
    ...mapGetters("gender", ["getGenders"]),
    ...mapGetters("ethnic", ["getEthnics"]),
    ...mapGetters("modelType", ["getModelTypes"]),
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("country", ["getCountries"]),
    ...mapGetters("language", ["getLanguages"]),
    ...mapGetters("languageLevel", ["getLanguageLevels"]),
    requiredRules
  },
  methods: {
    ...mapActions("modelType", ["loadModelTypes"]),
    ...mapActions("gender", ["loadGenders"]),
    ...mapActions("ethnic", ["loadEthnics"]),
    ...mapActions("country", ["loadCountries"]),
    ...mapActions("user", ["createUser", "updateUser"]),
    ...mapActions("language", ["loadLanguages"]),
    ...mapActions("languageLevel", ["loadLanguageLevels"]),
    setData({ model }) {
      if (model) {
        this.form = { ...model };
      }
    },
    async addUser() {
      try {
        if (!this.$refs.userForm.validate()) {
          return;
        }
        this.sendRequestLoading = true;
        this.setAge();
        if (this.form.id) {
          const has_language =
            this.form.languagesWithLevel && this.form.languagesWithLevel.length;
          if (has_language) {
            this.form.languagesWithLevel.map(({ id }) => id);
          }
          await this.updateUser(this.form);
        } else {
          await this.createUser(this.form);
        }
        this.$_closeModal();
      } catch (e) {
        return e;
      } finally {
        this.sendRequestLoading = false;
      }
    },
    setAge() {
      if (this.form.birthday) {
        let ageDifMs = Date.now() - new Date(this.form.birthday).getTime();
        var ageDate = new Date(ageDifMs);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        this.form.age = age;
      }
    },
  },
  created() {
    this.loadModelTypes();
    this.loadGenders();
    this.loadEthnics();
    this.loadCountries();
    this.loadLanguages();
    this.loadLanguageLevels();
  },
};
</script>
<style scoped>
.title {
  color: var(--color-light-gray);
  font-family: "Montserrat-regular" !important;
  font-size: 9px !important;
  height: 22px;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
}
.variables {
  font-family: "montserrat-light";
  font-size: 18px;
}
.variable__chip {
  font-family: "montserrat-light";
}
</style>
