<template>
  <basic-modal @open="setData" width="1300" transition="slide-x-transition">
    <template #header>
      <divider
          title="Email Confirmation"
          sub-title="a final check"
          size="none"
      ></divider>
    </template>
    <template #default>
      <div class="mt-4 px-12 pb-16">
        <SectionTitle title="recipients" />
        <div class="pt-2">
          <v-row>
            <v-col cols="12" md="3">
              <RequestDetailsInformation
                  class="wa__font__m"
                  title="Total Recipients"
                  :subTitle="form.length"
              />
            </v-col>
            <v-col cols="12" md="9">
              <li class="request__details__information">
                <div
                    class="
                    d-block
                    ml-0 ml-md-1
                    request__details__information__line
                  "
                >
                  <div class="request__details__information--title">emails</div>
                  <div class="request__details__information--sub-title d-flex">
                    <v-chip
                        class="
                        ma-1
                        request__details__information--sub-title--block
                      "
                        color="#e6e6e673"
                        text-color="black"
                        label
                        x-small
                        v-for="(item, i) in form"
                        v-bind:key="i"
                    >
                      {{ item.email }}
                    </v-chip>
                  </div>
                </div>
              </li>
            </v-col>
            <v-col cols="12" md="9">
              <li class="request__details__information">
                <div
                    class="
                    d-block
                    ml-0 ml-md-1
                    request__details__information__line
                  "
                >
                  <div class="request__details__information--title">
                    filters
                  </div>
                  <div class="request__details__information--sub-title d-flex">
                    <v-chip
                        class="
                        mr-1
                        request__details__information--sub-title--block
                      "
                        color="#e6e6e673"
                        text-color="black"
                        label
                        x-small
                        v-for="(filter, i) in activeFilters"
                        v-bind:key="i"
                    >
                      <span v-if="filter.type === 'like'"
                      >{{ filter.name }} : {{ filter.val }}</span
                      >
                      <span v-else-if="filter.value"
                      >{{ filter.name }} : {{ filter.value }}</span
                      >
                      <span v-else-if="filter.type === 'in' && !filter.values"
                      >{{ filter.name }} : {{ filter.val.join(" , ") }}</span
                      >
                      <span v-else-if="filter.type === 'in' && !filter.multiple"
                      >{{ filter.name }} :
                        {{
                          filter.values.find((x) => x.id === filter.val).name
                        }}</span
                      >
                      <span v-else-if="filter.type === 'in' && filter.multiple"
                      >{{ filter.name }} :
                        {{
                          filter.val
                              .map(
                                  (val) =>
                                      filter.values.find((x) => x.id === val).name
                              )
                              .join(" , ")
                        }}</span
                      >
                      <span v-else-if="(filter.type = 'between')"
                      >{{ filter.name }} : from ({{ filter.val1 }}) to ({{
                          filter.val2
                        }})</span
                      >
                    </v-chip>
                  </div>
                </div>
              </li>
            </v-col>
          </v-row>
        </div>
        <div class="mt-16 pb-16">
          <SectionTitle title="recipients" />
          <div class="pt-4">
            <v-row>
              <v-col cols="12">
                <RequestDetailsInformation
                    class="wa__font__m"
                    title="Title"
                    :subTitle="form[0] && form[0].title ? form[0].title : ''"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <RequestDetailsInformation
                    class="wa__font__m"
                    title="text"
                    tag="html"
                    :subTitle="form[0] && form[0].message ? form[0].message : ''"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <v-spacer></v-spacer>
      <div
          class="
          px-7
          pt-2
          pb-3
          d-flex
          justify-space-between
          align-items-center
          w-100
        "
      >
        <block-button
            class="btn__modal--cancel pl-0 btn__size__18"
            text="cancel"
            icon="WMi-cancel-linear"
            text-mode="text"
            @click.native="$_closeModal()"
        ></block-button>
        <block-button
            height="30"
            class="btn__modal--assign ml-5 site__button width-auto btn__size__16"
            text="approve info and send the email"
            :loading="requestLoading"
            @click.native="sendEmail"
        ></block-button>
      </div>
    </template>
  </basic-modal>
</template>
<script>
import SectionTitle from "@/components/Global/Section/SectionTitle.vue";
import RequestDetailsInformation from "@/components/Global/Section/RequestDetailsInformation.vue";
import UserRepository from "@/abstraction/repository/userRepository";
const userRepository = new UserRepository();
export default {
  name: "modal_emailConfirmation",
  props: {
    filters: {
      default: {},
    },
    sorts: {
      default: "",
    },
  },
  components: {
    SectionTitle,
    RequestDetailsInformation,
  },
  data() {
    return {
      form: [],
      requestLoading : false,
    };
  },
  methods: {
    setData({ model }) {
      if (model) {
        this.form = [...model];
      }
    },
    async sendEmail() {
      try {
        this.requestLoading = true;
        await userRepository.emailSender({ batch: this.form[0].batch });
        this.$_closeModal();
      } catch (error) {
        return error;
      } finally {
        this.requestLoading = false;
      }
    },
  },
  computed: {
    activeFilters() {
      const keys = Object.keys(this.filters);
      if (keys.length !== 0) {
        const activeFilters = [];
        keys.map((key) => {
          const hasVal =
              (this.filters[key].val && this.filters[key].val.length) ||
              (this.filters[key].value && this.filters[key].value !== "all") ||
              typeof this.filters[key].val === "number" ||
              this.filters[key].val1 ||
              this.filters[key].val2;
          if (hasVal) {
            activeFilters.push(this.filters[key]);
          }
        });
        return activeFilters;
      }
      return [];
    },
  },
};
</script>
<style scoped>
.request__details__information--sub-title.html-noscroll p { margin-block-start: 0; margin-block-end: 0; margin-inline-start: 0px; margin-inline-end: 0px; padding:0; margin:0 !Important; }
html.html-noscroll {
  overflow: hidden;
}
.btn__modal--assign {
  padding: 20px 35px !important;
  font-family: "Montserrat-ExtraLight";
  border-radius: 5px !important;
}
.request__details__information {
  display: flex;
}
/* .request__details__information__line::before {
  height: 20px;
  background-color: var(--color-light-gray);
  content: "";
  display: block;
  width: 1px;
  margin: 7px 0;
} */
.request__details__information__line {
  border-left: 1px solid var(--color-light-gray);
  padding-left: 7px;
}
.request__details__information--title {
  color: var(--color-smoky);
  font-size: 11px;
  font-family: "Montserrat-ExtraLight";
  text-transform: uppercase;
}
.request__details__information--sub-title {
  flex-wrap: wrap;
}
.request__details__information--sub-title--block {
  font-family: "Montserrat-regular";
}
</style>
<style>
.wa__font__m div .request__details__information--sub-title {
  font-family: "Montserrat";
}
.request__details__information--sub-title.html-noscroll p { margin-block-start: 0; margin-block-end: 0; margin-inline-start: 0px; margin-inline-end: 0px; padding:0; margin:0 !Important; }
</style>
