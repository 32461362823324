<template>
  <div class="wa__model__clients mb-1">
    <v-row class="text-left h-inherit">
      <!--name  section-->
      <v-col cols="3">
        <div class="d-flex align-center h-100">
          <Checkbox
            v-model="getUserSelected"
            class="ml-3 mr-2"
            name="checkbox"
            :item="client"
            @change="changeUserSelected"
          />
          <div class="client__box">
            <div class="client__name wa__f__m__eb" v-if="$_getlocaleParam() === 'en'">
              {{ client.name.trim()!==''  && client.name !==null ? client.name : client.name_jpn }}
            </div>
            <div class="client__name wa__f__m__eb" v-else>
              {{ client.name_jpn.trim()!=='' && client.name_jpn !==null ? client.name_jpn : client.name }}
            </div>
            <small class="client__cell">{{ client.cell_phone }}</small>
          </div>
        </div>
      </v-col>
      <!-- role section -->
      <v-col cols="2">
        <div class="d-flex align-center h-100">
          <v-chip
            :class="[
              'role__chip text-uppercase',
              {
                manager__role: client.type === 'manager',
                model__role: client.type === 'model',
                client__role: client.type === 'client',
              },
            ]"
            :color="
              client.type === 'manager'
                ? 'black'
                : client.type === 'model'
                ? 'grey lighten-3'
                : 'white'
            "
            :text-color="
              client.type === 'manager'
                ? 'white'
                : client.type === 'model'
                ? 'black'
                : 'black'
            "
            small
            label
            >{{ client.type }}</v-chip
          >
        </div>
      </v-col>
      <!-- request section-->
      <v-col cols="3" class="wa__model__clients__request pl-5">
        <div class="wa__model__clients__request--request pl-5">
          <div class="line-20">
            <block-button
              height="16"
              class="btn-request"
              :text="client.requestCount + ' Total Requests'"
              color="black"
              bg-color="bgGray"
            />
            <div>
              <block-button
                height="auto"
                class="btn-approved"
                color="cyan"
                bg-color="bgCyan"
                :text="client.requestApprovedCount + ' Total Approved'"
              />
            </div>
          </div>
        </div>
      </v-col>
      <!-- /request section-->
      <!-- control section-->
      <v-col cols="4" class="d-flex justify-content-end align-items-center">
        <div class="pr-3">
          <circle-button
            icon="mdi-account-convert"
            color="cyan"
            bg-color="bgCyan"
            v-if="client.type !== 'model' && client.type !== 'manager'"
            @click.native.stop="
              $_openModal('changeStatus', {
                client: client,
              })
            "
          ></circle-button>
          <circle-button
            icon="WMi-mail-alt"
            color="black"
            @click.native="$_openModal('sendEmail', { model: client })"
          ></circle-button>
          <circle-button
            icon="WMi-align-left"
            color="black"
            @click.native="$_openModal('clientDetails', { model: client })"
          ></circle-button>
          <circle-button
            icon="WMi-pencil"
            color="black"
            @click.native="$_openModal('add_user', { model: client })"
          ></circle-button>
          <menu-item :items="menuItems" />
        </div>
      </v-col>
      <!-- /control section-->
    </v-row>
  </div>
</template>
<script>
// import Name from "../Global/Section/Name.vue";
import { mapActions, mapGetters } from "vuex";
import toast from "@/utils/toast";
export default {
  data() {
    return {
      details: {
        title: "CLIENT DETAILS",
        subTitle: "THE DETAILS YOU NEED",
      },
    };
  },
  components: {
    // Name,
  },
  props: {
    client: {
      type: Object,
    },
  },
  methods: {
    ...mapActions("user", [ "addToSelected","deleteUser", "removeFromSelected"]),
    deleteMessage() {
      toast.question(this.$_trans("toast.del_client_item_msg"), this.$_trans("toast.del_client_item_title"), () => {
        this.deleteUser(this.client.id);
      });
    },
    changeUserSelected() {
      const index = this.getUserSelected.findIndex((x) => x.id === this.client.id);
      if (index >= 0) {
        this.addToSelected(this.client);
      } else {
        this.removeFromSelected(this.client);
      }
    },
  },
  computed: {
    ...mapGetters("user", ["getUserSelected"]),
    menuItems() {
      return [
        // {
        //   text: "Add a transaction",
        //   icon: "WMi-plus-linear",
        //   click: (self) => {
        //     self.$_openModal("japaneseTranlationModal", {
        //       model: self.model,
        //     });
        //   },
        // },
        {
          text: "delete",
          icon: "WMi-trash",
          click: (self) => {
            self.deleteMessage();
          },
        },
      ];
    },
  },
};
</script>
<style scoped>
.wa__model__clients {
  border: 1px solid var(--color-light-gray);
  border-radius: 5px;
  width: 100%;
  height: 67px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.client__box {
  line-height: 15px;
  display: grid;
}
.client__name {
  font-family: "montserrat-light";
  font-size: 18px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}
.client__cell {
  font-size: 10px;
  color: var(--color-smoky);
}
.wa__model__clients__name {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}
.wa__model__clients__name--checked {
  padding: 0px 9px 0px 0;
}
/* role column */
.role__chip {
  font-family: "montserrat-regular";
  font-size: 12px;
  letter-spacing: 2px;
}
.client__role {
  border: 1px solid var(--color-black) !important;
}

.btn-request {
  font-size: 9px;
  font-family: "Montserrat-bold";
  letter-spacing: 2px !important;
  border-radius: 1px !important;
  cursor: default !important;
  /* position: relative;
  top: -3px; */
}
.btn-approved {
  font-family: "Montserrat-bold";
  font-size: 13px;
  font-weight: 900;
  padding: 1px 8px !important;
  /* position: relative;
  top: -0.5rem; */
  border-radius: 1px !important;
  letter-spacing: 2px;
}
.h-inherit {
  height: inherit;
}
.line-20 {
  line-height: 20px;
}
</style>
