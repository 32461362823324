var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wa__model__clients mb-1"},[_c('v-row',{staticClass:"text-left h-inherit"},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticClass:"d-flex align-center h-100"},[_c('Checkbox',{staticClass:"ml-3 mr-2",attrs:{"name":"checkbox","item":_vm.client},on:{"change":_vm.changeUserSelected},model:{value:(_vm.getUserSelected),callback:function ($$v) {_vm.getUserSelected=$$v},expression:"getUserSelected"}}),_c('div',{staticClass:"client__box"},[(_vm.$_getlocaleParam() === 'en')?_c('div',{staticClass:"client__name wa__f__m__eb"},[_vm._v(" "+_vm._s(_vm.client.name.trim()!=='' && _vm.client.name !==null ? _vm.client.name : _vm.client.name_jpn)+" ")]):_c('div',{staticClass:"client__name wa__f__m__eb"},[_vm._v(" "+_vm._s(_vm.client.name_jpn.trim()!=='' && _vm.client.name_jpn !==null ? _vm.client.name_jpn : _vm.client.name)+" ")]),_c('small',{staticClass:"client__cell"},[_vm._v(_vm._s(_vm.client.cell_phone))])])],1)]),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex align-center h-100"},[_c('v-chip',{class:[
            'role__chip text-uppercase',
            {
              manager__role: _vm.client.type === 'manager',
              model__role: _vm.client.type === 'model',
              client__role: _vm.client.type === 'client',
            },
          ],attrs:{"color":_vm.client.type === 'manager'
              ? 'black'
              : _vm.client.type === 'model'
              ? 'grey lighten-3'
              : 'white',"text-color":_vm.client.type === 'manager'
              ? 'white'
              : _vm.client.type === 'model'
              ? 'black'
              : 'black',"small":"","label":""}},[_vm._v(_vm._s(_vm.client.type))])],1)]),_c('v-col',{staticClass:"wa__model__clients__request pl-5",attrs:{"cols":"3"}},[_c('div',{staticClass:"wa__model__clients__request--request pl-5"},[_c('div',{staticClass:"line-20"},[_c('block-button',{staticClass:"btn-request",attrs:{"height":"16","text":_vm.client.requestCount + ' Total Requests',"color":"black","bg-color":"bgGray"}}),_c('div',[_c('block-button',{staticClass:"btn-approved",attrs:{"height":"auto","color":"cyan","bg-color":"bgCyan","text":_vm.client.requestApprovedCount + ' Total Approved'}})],1)],1)])]),_c('v-col',{staticClass:"d-flex justify-content-end align-items-center",attrs:{"cols":"4"}},[_c('div',{staticClass:"pr-3"},[(_vm.client.type !== 'model' && _vm.client.type !== 'manager')?_c('circle-button',{attrs:{"icon":"mdi-account-convert","color":"cyan","bg-color":"bgCyan"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$_openModal('changeStatus', {
              client: _vm.client,
            })}}}):_vm._e(),_c('circle-button',{attrs:{"icon":"WMi-mail-alt","color":"black"},nativeOn:{"click":function($event){return _vm.$_openModal('sendEmail', { model: _vm.client })}}}),_c('circle-button',{attrs:{"icon":"WMi-align-left","color":"black"},nativeOn:{"click":function($event){return _vm.$_openModal('clientDetails', { model: _vm.client })}}}),_c('circle-button',{attrs:{"icon":"WMi-pencil","color":"black"},nativeOn:{"click":function($event){return _vm.$_openModal('add_user', { model: _vm.client })}}}),_c('menu-item',{attrs:{"items":_vm.menuItems}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }